import * as React from "react"

export default function MenuItem({photo, title, description, size, price, company}) {
  return (
    <div className="col-12">
      <div className="menu-small-card-col">
        <div className="menu-small-card">
    {/*
          <a href="javascript:void(0);" className="img-wrap" style={{paddingBottom: '12px', boxSizing: 'content-box'}}>
            <img src={photo} alt="photo" />
          </a>
          */}
          <div className="text-content-wrap">
            <h6 className="extra-small-title" style={{float: 'left', fontFamily: 'Kuunari Medium Compressed', fontSize: '1.5rem'}}>{title}</h6>
            <div className="menu-card-grams-price" style={{float: 'right'}}>
              <p className="grams-text">{size}</p>
              <p className="menu-item-price text-right" style={{color: '#cc8648', fontFamily: 'Kuunari Medium Compressed', fontSize: '1.5rem'}}>{price}</p>
            </div>
            <div className="menu-description" style={{float: 'none', clear: 'both'}}>
              <p className="small-text">{description}</p>
              {company && <p className="small-text" style={{color: 'gray'}}>{company}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
