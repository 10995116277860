import temp from "../images/meni-dorucak.jpg"

export const DrinksMenuContent = [
  {
    number: 1,
    title: "Penušava vina",
    link: "penusava-vina",
    photo: temp,
    meals: [
      {
        title: 'Pegoes Espumante Bruto',
        description: 'Arinto, Fernao pires',
        company: 'Adega de Pegoes, Portugal',
        price: '2400 rsd'
      },
      {
        title: 'Prosecco',
        description: 'Glera',
        company: 'Masottina, Trevizo-Italija',
        price: '520 rsd - 2500 rsd'
      },
      {
        title: 'La Volee',
        description: 'Blanc de Blancs',
        company: 'Geographic Wines, Bairrada Portugal',
        price: '4500 rsd'
      },
    ]
  },
  {
    number: 2,
    title: "Roze vina",
    link: "roze-vina",
    photo: temp,
    meals: [
      {
        title: 'Dajić roze',
        description: 'Rose',
        company: 'Vinarija Dajić, Mihajlovac-Negotin, Srbija',
        price: '500 rsd - 2400 rsd'
      },
      {
        title: 'Roze nostra',
        description: 'merlot,cabernet sauvignon',
        company: 'Erdevik vinarija - Fruška gora, Srbija',
        price: '2600 rsd'
      },
      {
        title: 'Pink punk',
        description: 'Cabernet franc, Merlot, Cabernet sauvignon',
        company: 'Chichateu  vinarija, Šišatovac-Fruška gora, Srbija',
        price: '2700 rsd'
      },
      {
        title: 'Vinum chardonnay',
        description: 'Chardonnay',
        company: 'Vinum vinarija, Sremski Karlovci, Srbija',
        price: '560 rsd - 2600 rsd'
      }
    ]
  },
  {
    number: 3,
    title: "Bela vina",
    link: "bela-vina",
    photo: temp,
    meals: [
      {
        title: 'Furmint devas',
        description: 'Furmint',
        company: 'Vinarija Saigmeister-Irig,Fruška gora,Srbija',
        price: '2800 rsd'
      },
      {
        title: 'Matijašević Belina',
        description: 'Smederevka',
        company: 'Vinarija Matijašević, Orašac-Šumadija, Srbija',
        price: '2800 rsd'
      },
      {
        title: 'Imperator Grašac    Organic wine',
        description: 'Grašac',
        company: 'Vinarija Imperator, Fruška gora, Srbija',
        price: '2900 rsd'
      },
      {
        title: 'Lastar tamjanika',
        description: 'Tamjanika',
        company: 'Vinarija Latar-Sekurić, Rekovac, Srbija',
        price: '620 rsd - 3100 rsd'
      },
      {
        title: 'Jelić morava',
        description: 'Morava',
        company: 'Vinarija Jelić, Valjevo, Srbija',
        price: '3200 rsd'
      },
      {
        title: 'Bjelica Babaroga',
        description: 'Chardonnay',
        company: 'Vinarija Bjelica, Fruška gora, Srbija',
        price: '8200 rsd'
      },
      {
        title: 'Landcraft Loureiro',
        description: 'Loureiro',
        company: 'Geographic Wines, Bairrada Portugal',
        price: '3900 rsd'
      },
      {
        title: 'Baron do Sil Godello',
        description: 'Godello',
        company: 'Premium Fincas-Rias Baixas, Spain',
        price: '4600 rsd'
      },
      {
        title: 'Druida Reserva',
        description: 'Encruzado',
        company: 'Nuno Mira do O,Dao-Portugal',
        price: '4800 rsd'
      },
    ]
  },
  {
    number: 4,
    title: "Crvena vina",
    link: "crvena-vina",
    photo: temp,
    meals: [
      {
        title: 'Madžić merlot',
        description: 'Merlot',
        company: 'Madžić vinarija, Selevac, Šumadija, Srbija',
        price: '560 rsd - 2600 rsd'
      },
      {
        title: 'Raj Game',
        description: 'Gammey',
        company: 'Vinarija Raj, Negotin, Srbija',
        price: '2800 rsd'
      },
      {
        title: 'Sagmeister pinot noir',
        description: 'Pinot noir',
        company: 'Sagmeister vinarija, Kanjiža, Srbija',
        price: '680 rsd - 3400 rsd'
      },
      {
        title: 'Animus KII    Organic wine',
        description: 'Malbec, Cabernet Sauvignon, Cabernet Franc',
        company: 'Vinarija Imperator, Fruška gora, Srbija',
        price: '740 rsd - 3700 rsd'
      },
      {
        title: 'Radovan 100% prokupuac',
        description: 'Prokupac',
        company: 'Podrum Čokot, Starci, Župa, Srbija',
        price: '3800 rsd'
      },
      {
        title: 'Zapis merlot',
        description: 'Merlot',
        company: 'Janko vinarija, Smederevo, Srbija',
        price: '3800 rsd'
      },
      {
        title: 'The Merlot',
        description: 'Merlot',
        company: 'Madžić vinarija, Selevac, Šumadija, Srbija',
        price: '3900 rsd'
      },
      {
        title: 'Cabernet sauvignon reserva',
        description: 'Cabernet sauvignon',
        company: 'Podrum Radovanović, Krnjevo, Šumadija, Srbija',
        price: '5900 rsd'
      },
      {
        title: 'Dentelle',
        description: 'Cabernet sauvignon',
        company: 'Aglaja vinarija, Negotin, Srbija',
        price: '6800 rsd'
      },
      {
        title: 'Stiflers mom',
        description: 'Syrah',
        company: 'Vinarija Erdevik, Fruška gora, Srbija',
        price: '7900 rsd'
      },
      {
        title: 'Andreza Reserva',
        description: 'Vinhas Velhas',
        company: 'Lua Cheia em Vinhas Velhas, Doc Douro-Portugal',
        price: '3100 rsd'
      },
      {
        title: 'Marques de Tomares Crianza',
        description: 'Tempranilo, Graciano',
        company: 'Premium Fincas-Rioja, Spain',
        price: '3400 rsd'
      },
      {
        title: 'Nuestro 20 Meses',
        description: 'Tinto fino',
        company: 'Bodegas Diaz Bayo - Ribera del Duero, Spain',
        price: '4800 rsd'
      }
    ]
  },
  {
    number: 5,
    title: "Kokteli",
    link: "kokteli",
    photo: temp,
    meals: [
      {
        title: 'Black Walnut O.F',
        description: 'Branko Premium,Walnut Liq,Maple Syrup,Triple bitter',
        price: '820 rsd'
      },
      {
        title: 'Branko Sour',
        description: 'Branko Plum,Lemon juice,Coconut Syrup,Egg White,Angostura Bitter',
        price: '750 rsd'
      },
      {
        title: 'Srpsko Budjenje',
        description: 'Branko Premium,Kahlua,Cherry Jam',
        price: '750 rsd'
      },
      {
        title: 'Good Guys',
        description: 'Good Guys Gin,Lemon juice,Elderflower syrup,Mint,Prosseco',
        price: '720 rsd'
      },
      {
        title: 'Sir.William',
        description: 'Branko Apple,Branko Pear,Manzanita Liq,Winter Spice Syrup,Pear Juice',
        price: '720 rsd'
      },
      {
        title: 'Plum Amaretto Sour',
        description: 'Branko Plum,Amaretto Disaronno,Lemon juice,Simple Syrup',
        price: '720 rsd'
      },
      {
        title: 'Young King',
        description: 'Branko Plum,Cynar,Ramazzoti,Aperol,Absinth bitter',
        price: '720 rsd'
      },
      {
        title: 'Espresso Martini',
        description: 'Vodka,Kahlua,Symple Syrup,Espresso',
        price: '720 rsd'
      },
      {
        title: 'Cherry Spritz',
        description: 'Campari,Cherrry liqueur, Paragon Timur Berry, Prosecco, Sparkling water',
        price: '720 rsd'
      },
      {
        title: 'The Empress',
        description: 'Quince rakija, Paragon Rue Berry, Lemon juice, Cocchi Americano, Cointreu orange, Dash Absinthe.',
        price: '720 rsd'
      },
      {
        title: 'The White Queen',
        description: 'Quince rakija, Monin Spicy syrup, Monin Crème de Cacao Blanc, Lemon juice, Paragon White Penja Pepper.',
        price: '720 rsd'
      },
      {
        title: 'Tennessee Timur',
        description: 'Apple rakija, Bourbon, Fernet Branca, Paragon Timur Berry.',
        price: '720 rsd'
      },
      {
        title: 'Mocktail',
        description: 'Apple Juice,Elderflower Syrup,Mint,Soda-Water (no alcohol)',
        price: '500 rsd'
      }
    ]
  }
]

