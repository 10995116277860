import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import DrinksMenuItem from "../components/DrinksMenuItem"
import {DrinksMenuContent} from "../components/DrinksMenuContent"
import {DrinksMenuTabs} from "../components/DrinksMenuTabs"
import { SEO as Seo} from "../components/seo"

const MeniPage = () => {
  const [ activeTab, setActiveTab ] = React.useState(4);
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Vinska i koktel karta" />
      <main className="main-content">
        <div className="container">
          <h1 className="title-of-page center-aligned">Vina i kokteli</h1>

          <div className="main-menu-section -bottom-indent">
            <div className="row">
              <div className="col-12">
                <div className="description-text intro-text center-aligned">
                  <p>Iako o ukusima ne treba raspravljati, kada bacite pogled na naša pića, rasprava je neizbežna, jer će vam sigurno biti teško da odaberete samo jedno piće.</p>
                </div>
              </div>
            </div>

            <div className="d-md-none col-md-6">
              <video autoPlay muted loop controls playsInline width="100%">
                <source src="/instories_A3F86DF3-8FD5-4349-9AAE-0E6C9165D33C.webm" type="video/webm" />
                <source src="/instories_A3F86DF3-8FD5-4349-9AAE-0E6C9165D33C.mp4" type="video/mp4" />
              </video>
            </div>

            <DrinksMenuTabs
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />

            <div className="tab-content">
              {DrinksMenuContent.map((i) => (
                <div className={`tab-pane fade ${activeTab === i.number && " active show"}`} id={`${i.link}`} role="tabpanel" aria-labelledby={`${i.link}-tab`}>
                </div>
              ))}
              <div className="row">
                <div className="d-none d-md-block col-md-6">
                  <video autoPlay muted controls loop playsInline width="100%">
                    <source src="/instories_A3F86DF3-8FD5-4349-9AAE-0E6C9165D33C.webm" type="video/webm" />
                    <source src="/instories_A3F86DF3-8FD5-4349-9AAE-0E6C9165D33C.mp4" type="video/mp4" />
                  </video>
                </div>

                {DrinksMenuContent.map((i) => (
                  <div className="col-sm-12 col-md-6 col-lg-6 small-gutters align-items-end" style={{display: activeTab === i.number ? 'flex' : 'none', flexWrap: 'wrap', alignContent: 'start', paddingTop: '2rem'}}>
                    <p style={{
                      paddingLeft: '5px',
                      marginBottom: '2rem',
                      color: 'gray'
                    }}>Čaša: 0,15l, flaša: 0,75l</p>
                    {i.meals.map((m) => (
                      <DrinksMenuItem
                        title={m.title}
                        photo={m.photo}
                        description={m.description}
                        size={m.size}
                        price={m.price}
                        company={m.company}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

      </main>
    </Layout>
  )
}

export default MeniPage

export const Head = () => (
  <Seo />
)
